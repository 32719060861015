<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <div
      class="checkout-items"
    >
      <b-card
        v-for="item in items"
        :key="item.product.slug"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="item-img">
          <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
            <b-img
              :alt="`${item.product.slug}-${item.product.id}`"
              :src="$options.filters.mediaUrl(item.product, 'avatar', '320x320')"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link
                :to="{ name: 'products-show', params: { slug: item.product.slug } }"
                class="text-body"
              >
                {{ $options.filters.transl(item.product, 'title') }}
              </b-link>
            </h6>
            <b-card-text
              v-if="item.product.categories"
              class="item-company"
            >
              <b-badge
                v-for="(category, cin) in item.product.categories"
                :key="cin + '_' + 'cat'"
                pill
                class="mr-75"
                variant="light-primary"
              >
                {{ $options.filters.transl(category, 'title') }}
              </b-badge>
            </b-card-text>
          </div>
          <div class="item-quantity mb-50">
            <b-form-select
              v-model="item.priceType"
              :options="transformPrices(item.product.prices)"
              size="sm"
              @input="updateItems"
            />
          </div>
          <div class="item-quantity">
            <b-form-spinbutton
              v-model="item.quantity"
              size="sm"
              inline
              @input="updateItems"
            />
          </div>
        </b-card-body>

        <!-- Product Info -->
        <b-card-body>
          <b-card
            no-body
            class="card-app-design mb-0"
          >
            <div class="design-planning-wrapper type-1-1-1">
              <div
                v-b-tooltip.hover.v-primary
                class="design-planning"
                :title="$t('catalog.item.descriptions.views')"
              >
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.views') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.fFormat(getHistory(item).views) }}
                </h6>
              </div>
              <div
                v-b-tooltip.hover.v-primary
                class="design-planning"
                :title="$t('catalog.item.descriptions.er')"
              >
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.er') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.numberFormat(getHistory(item).er) + '%' }}
                </h6>
              </div>
              <div
                v-b-tooltip.hover.v-primary
                class="design-planning"
                :title="$t('catalog.item.descriptions.cpm')"
              >
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.cpm') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.moneyFormat(getHistory(item).cpv * 1000) }}
                </h6>
              </div>
            </div>
            <div class="design-planning-wrapper type-1-1">
              <div
                v-b-tooltip.hover.v-primary
                class="design-planning"
                :title="$t('catalog.item.descriptions.subscribers')"
              >
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.subscribers') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.numberFormat(getHistory(item).subscribers) }}
                </h6>
              </div>
              <div
                v-b-tooltip.hover.v-primary
                class="design-planning"
                :class="item.product.sex"
                :title="$t('catalog.item.descriptions.sex_ratio')"
              >
                <p class="card-text mb-25 gender_icons">
                  <feather-icon
                    icon="UserIcon"
                    class="text-primary"
                  />
                  <feather-icon
                    icon="UserIcon"
                    class="text-danger"
                  />
                </p>
                <div class="line">
                  <div
                    class="blue"
                    :style="`width:${item.product.sex_ratio}%;`"
                  />
                </div>
              </div>
            </div>
          </b-card>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="mt-1 item-wrapper text-center">
            <div
              v-if="calcDiscount(item)"
              class="item-cost"
            >
              <del class="item-price">
                {{ priceFormatted(calcPrice(item)) }}
              </del>
              <h4 class="item-price text-danger">
                {{ priceFormatted(calcDiscount(item)) }}
              </h4>
            </div>
            <div
              v-else
              class="item-cost"
            >
              <h4 class="item-price">
                {{ priceFormatted(calcPrice(item)) }}
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            class="mt-1 remove-wishlist"
            @click="removeItemFromCart(item.product)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>{{ $t('cart.page.btn.remove') }}</span>
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <div class="checkout_buttons">
          <b-button
            variant="outline-primary"
            :to="{ name: 'products-index' }"
          >
            {{ $t('cart.page.btn.catalog') }}
          </b-button>
          <b-button
            variant="warning"
            class="hidden"
            @click="savePack"
          >
            {{ $t('cart.page.btn.save') }}
          </b-button>
        </div>
      </b-card>

      <b-card>

        <div class="price-details">
          <h6 class="price-title">
            {{ $t('cart.page.titles.totals') }}
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                {{ $t('cart.page.titles.channels') }}
              </div>
              <div class="detail-amt">
                {{ totalChannels }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                {{ $t('cart.page.titles.quantity') }}
              </div>
              <div class="detail-amt discount-amt text-success">
                {{ totalQuantity }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                {{ $t('catalog.item.labels.subscribers') }}
              </div>
              <div class="detail-amt">
                {{ $options.filters.numberFormat(totalSubscribers) }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                {{ $t('catalog.item.labels.views') }}
              </div>
              <div class="detail-amt">
                ~ {{ $options.filters.fFormat(totalViews) }}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                {{ $t('cart.widget.titles.total') }}
              </div>
              <div class="detail-amt font-weight-bolder">
                {{ this.$options.filters.moneyFormat(subTotalAmount) }}
              </div>
            </li>
          </ul>
          <template v-if="totalSale">
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">
                  {{ $t('cart.widget.titles.sale') }}
                </div>
                <div class="detail-amt font-weight-bolder text-error">
                  - {{ this.$options.filters.moneyFormat(totalSale) }}
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title detail-total">
                  {{ $t('cart.widget.titles.saletotal') }}
                </div>
                <div class="detail-amt font-weight-bolder text-error">
                  {{ this.$options.filters.moneyFormat(totalAmount) }}
                </div>
              </li>
            </ul>
          </template>
          <b-button
            v-if="canToOrder"
            variant="primary"
            block
            @click="placeOrder"
          >
            {{ $t('cart.page.btn.order') }}
          </b-button>
          <template v-else>
            <b-alert
              variant="danger"
              :show="true"
              class="mb-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ $t('cart.page.titles.empty_money') }}
              </div>
            </b-alert>
            <b-button
              variant="warning"
              block
              @click="openedAddFound = true;"
            >
              Поповнити рахунок
            </b-button>
          </template>
        </div>

      </b-card>
    </div>

    <login-modal
      v-if="!userData"
      :opened-login-modal.sync="openedLoginModal"
    />
    <AddFound
      v-if="userData"
      :opened-add-found.sync="openedAddFound"
      :amount="500"
      :settings="userData.transaction_settings"
      @init-payment="initPayment"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { useEcommerceUi } from '@/views/catalog/useEcommerce'
import GlobalMixin from '@/mixins/GlobalMixin'
import LoginModal from '@/views/authentication/LoginModal'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import AddFound from '@/views/account/setting/finance/AddFound'

export default {
  components: {
    AddFound,
    LoginModal,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      balance: 0,
      items: [],
      projects: [],
      openedLoginModal: false,
      userData: JSON.parse(localStorage.getItem('userData')) ?? null,
      wayforpay: null,
      openedAddFound: this.$route.query.action === 'found' ?? false,
    }
  },
  computed: {
    subTotalAmount() {
      let total = 0
      // this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) * i.quantity })
      this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) })
      return total ? parseInt(total, 10) : 0
    },
    totalSale() {
      let total = 0
      this.items.forEach(i => {
        // total += (_.includes(this.projects, i.product.id) && i.product.has_sale === 'enabled' && i.product.sale) ? parseInt(((i.product.sale * (this.calcDiscount(i) || this.calcPrice(i)) * i.quantity) / 100), 10) : 0
        total += (_.includes(this.projects, i.product.id) && i.product.has_sale === 'enabled' && i.product.sale) ? parseInt(((i.product.sale * (this.calcDiscount(i) || this.calcPrice(i))) / 100), 10) : 0
      })
      return total ? parseInt(total, 10) : 0
    },
    totalAmount() {
      return this.subTotalAmount - this.totalSale
    },
    totalQuantity() {
      let total = 0
      this.items.forEach(i => { total += i.quantity })
      return total
    },
    totalChannels() {
      return this.items.length
    },
    totalSubscribers() {
      let total = 0
      this.items.forEach(i => { total += this.getHistory(i).subscribers })
      return total
    },
    totalViews() {
      let total = 0
      this.items.forEach(i => { total += this.getHistory(i).views * i.quantity })
      return total
    },
    canToOrder() {
      return !this.userData || this.balance >= this.totalAmount
    },
  },
  created() {
    if (this.userData) {
      this.$http.get('/api/profile').then(res => {
        const { balance } = res.data.data
        this.balance = balance
      })
      this.$http.get('/api/profile/projects').then(res => {
        this.projects = res.data.products
      })
    }
  },
  mounted() {
    this.fetchItems()
    const wayforpayScript = document.createElement('script')
    wayforpayScript.setAttribute('src', 'https://secure.wayforpay.com/server/pay-widget.js')
    document.head.appendChild(wayforpayScript)
  },
  methods: {
    transformPrices(prices) {
      return prices.map(i => ({
        text: this.$t(`catalog.price.types.${i.type}`),
        value: i.type,
      }))
    },
    selectedPrice(item) {
      return _.filter(item.product.prices, o => o.type === item.priceType)
    },
    selectedDiscount(item) {
      return _.filter(item.product.discounts, o => o.type === item.priceType)
    },
    calcPrice(item) {
      return this.selectedPrice(item) && this.selectedPrice(item).length ? this.selectedPrice(item)[0].price * item.quantity : 0
    },
    calcDiscount(item) {
      return this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price * item.quantity : 0
    },
    priceFormatted(price) {
      return this.$options.filters.moneyFormat(price)
    },
    getHistory(item) {
      let h = {
        subscribers: 0,
        views: 0,
        er: 0,
        cpv: 0,
      }
      const koef = this.koefOptions[item.priceType]
      if (item.product.historyLast) {
        h = {
          subscribers: item.product.historyLast.subscribers,
          views: item.product.historyLast.views * koef,
          er: ((item.product.historyLast.views * koef) / item.product.historyLast.subscribers) * 100,
          cpv: (this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price : this.selectedPrice(item)[0].price) / (item.product.historyLast.views * koef),
        }
      }
      return h
    },
    fetchItems() {
      this.items = Object.values(JSON.parse(localStorage.getItem('cart')))
    },
    updateItems() {
      localStorage.setItem('cart', JSON.stringify(this.items))
    },
    removeItemFromCart(product) {
      this.handleCartActionClick(product, '1day', 1)
      setTimeout(() => {
        this.fetchItems()
      }, 350)
    },
    savePack() {
      if (this.userData) {
        // console.log('save')
      } else {
        this.openedLoginModal = true
      }
    },
    async placeOrder() {
      if (this.userData) {
        const data = {
          products: this.items.map(i => ({
            product_id: i.product.id,
            quantity: i.quantity,
            type: i.priceType,
          })),
        }

        this.$http.post('/api/user/projects', data)
          .then(response => {
            localStorage.setItem('cart', JSON.stringify({}))
            store.commit('catalog/UPDATE_CART_ITEMS', {})
            store.commit('catalog/UPDATE_CART_ITEMS_COUNT', 0)
            router.replace({ name: 'account:projects-start', params: { id: response.data.data.id } })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.openedLoginModal = true
      }
    },
    wfpInit() {
      this.wayforpay = new window.Wayforpay()
      window.addEventListener('message', event => {
        if (event.data === 'WfpWidgetEventClose'
          || event.data === 'WfpWidgetEventApproved'
          || event.data === 'WfpWidgetEventDeclined'
          // event.data === 'WfpWidgetEventPending' // транзакция на  обработке
        ) {
          this.wayforpay.closeit()
          this.wayforpay = null
          window.location.reload()
        }
      })
    },
    initPayment({ method, invoice }) {
      if (method === 'wayforpay' && invoice.url) {
        this.wfpInit()
        setTimeout(() => {
          this.wayforpay.invoice(invoice.url, true)
        }, 500)
      }
      if (method === 'cryptomus' && invoice.url) {
        window.location.href = invoice.url
      }
    },
  },
  setup() {
    const { handleCartActionClick } = useEcommerceUi()

    return {
      handleCartActionClick,
    }
  },
}
</script>

<style lang="scss">
.ecommerce-application .product-checkout.list-view {
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
}

.checkout-items {
  .ecommerce-card {
    grid-template-columns: 1fr 2fr 2fr 1fr !important;

    .item-img {
      img {
        width: 150px !important;
      }
    }

    .item-quantity {
      & > * {
        width:150px !important;
      }
    }

    .card-app-design {

      .design-planning-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        align-items: stretch;
        gap:5px;
        margin:0 0;

        .design-planning {
          margin: 0 0 !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: help;

          &.disabled {
            filter:grayscale(1);
            pointer-events: none;
          }
        }

        &.type-1-1 {
          grid-template-columns: 2fr 1fr !important;
          margin:5px 0 0;

          .gender_icons {
            display: flex;
            justify-content: space-between;
            width: 100%;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          .line {
            height: 4px;
            width: 100%;
            display: flex;
            background: #ea5455;
            border-radius: 2px;

            .blue {
              display: flex;
              height: 4px;
              background: #7367f0;
              border-radius: 2px;
            }
          }
        }
      }
    }

    @media (max-width: 860px) {
      grid-template-columns: 1fr !important;

      .item-img {
        img {
          border-radius: 5px;
        }
      }

      .item-quantity {
        & > * {
          width:100% !important;
        }
      }
    }
  }
}

.checkout_buttons {
  display: grid;
  //grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr;
  gap: 1em;
}

@media screen and (max-width: 1360px) {
  .ecommerce-application .product-checkout.list-view {
    grid-template-columns: 1fr !important;
  }
}
</style>
