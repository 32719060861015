<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      :title="$t('cart.page.steps.cart')"
      icon="feather icon-shopping-cart"
    >
      <checkout-step-cart @next-step="formWizardNextStep" />
    </tab-content>

    <!-- address -->
    <tab-content
      :title="$t('cart.page.steps.setting')"
      icon="feather icon-settings"
    >
      <div />
    </tab-content>

    <!-- social link -->
    <tab-content
      :title="$t('cart.page.steps.start')"
      icon="feather icon-share"
    >
      <div />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import CheckoutStepCart from './CheckoutStepCart.vue'

export default {
  components: {
    FormWizard,
    TabContent,

    // SFC
    CheckoutStepCart,
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
